import { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/Filter.css';
import { IoSearchCircleOutline } from 'react-icons/io5';
import { LuCalendarDays } from 'react-icons/lu';
import Filter from '../components/Filter';
import {
  DataFilter, Client, DataLabels, Facility,
} from '../components/Interfaces';
import CalendarContainer from '../components/CalendarContainer';
import Footer from '../components/Footer';

export default function FilterScreen() {
  const location = useLocation();
  const [doctorFilter, setDoctorFilter] = useState<boolean>(false);
  const id: string = location?.state?.message;
  const [dataHour, setDataHour] = useState<DataFilter>({} as DataFilter);
  const [dataLabels] = useState({ facility: {} as Facility } as DataLabels);
  const [isCharge, setIsCharge] = useState<boolean>(false);
  const containerRef = useRef<any>();

  const clientData: Client = location?.state?.clientData ? location.state.clientData : ({} as Client);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  const setFunction = (value: DataFilter) => {
    setIsCharge(true);
    setDataHour(value);
    setShowCalendar(true);
    moveToEnd();
  };

  const moveToEnd = () => {
    const container: any = containerRef.current;
    if (container) container.scrollIntoView({ behavior: 'smooth', top: 'start' });
  };

  return (
    <div className="appBody">
      <div className={!showCalendar ? "filterMainContainer" : "fullScreen"}>
        <p className="containerTitle">
          {showCalendar ? <LuCalendarDays style={{ marginRight: '4%' }} size={45} />
            : <IoSearchCircleOutline style={{ marginRight: '4%' }} size={45} />}
          {showCalendar ? 'Reserva de hora' : 'Búsqueda de hora'}
        </p>
        <p className="containerSubTitle">
          {showCalendar ? 'Seleccione día y hora' : 'Complete los siguientes campos para completar su búsqueda'}

        </p>

        {!showCalendar
          ? (
            <div className="filterContainer formularioBusqueda">
              {id == '5'
                ? (
                  <div className="buttonSelectBar">
                    <button type="button" className={doctorFilter ? 'buttonNormal' : 'buttonNormalSelected'} onClick={() => setDoctorFilter(false)}>
                      Especialidad
                    </button>
                    <button type="button" className={doctorFilter ? 'buttonDoctorSelected' : 'buttonDoctor'} onClick={() => setDoctorFilter(true)}>
                      Profesional
                    </button>
                  </div>
                ) : null}
              <Filter
                id={id}
                doctorFilter={doctorFilter}
                clientData={clientData}
                dataLabels={dataLabels}
                setDataHour={setFunction}
                isCharge={isCharge}
              />
            </div>
          )
          : (
            <CalendarContainer
              groupId={id}
              clientData={clientData}
              dataLabels={dataLabels}
              dataHour={dataHour}
              setIsCharge={setIsCharge}
              containerRef={containerRef}
            />
          )}
      </div>

    </div>
  );
}
