import { useLocation, useNavigate } from 'react-router-dom';
import '../../styles/Confirmation.css';
import 'dayjs/locale/es';
import axios from 'axios';
import { useState } from 'react';
import { Client, DataHour } from '../Interfaces';

interface hourData {
  reservationCode: string,
  fecha: string,
  hora: string,
  profesional: string,
  prestacion: string,
  sucursal: string,
  status: string,
  address: string
}

interface Props {
  hourData: hourData,
  containerRef: React.MutableRefObject<any>
  rut: string
}

const ConfirmationInformation = ({ hourData, containerRef, rut }: Props) => {
  const [status, setStatus] = useState(hourData.status);
  const [street, setStreet] = useState();

  const toHome = () => {
    window.location.replace('https://maiposalud.cl');
  };

  const changeStatusHora = (status: number) => {
    axios.post('https://alma.maiposalud.com/alma/external/v1/confirmationWeb',
      {
        id_agendamiento: hourData.reservationCode,
        status,
        rut,
      },
      {
        headers: {
          Authorization: '2374dfa16c7475154d0e3539a7bcf785',
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        if (status == 1) {
          alert('Hora confirmada con éxito');
          setStatus('confirmado');
        } else {
          alert('Hora cancelada con éxito');
          setStatus('anulado');
        }
      }).catch((err) => console.log(err.response.data));
  };

  const findFacilitieAdress = (sucursalName: string) => {
    axios.post('https://alma.maiposalud.com/alma/external/pp/v1/facility', '', {
      headers: {
        Authorization: '2374dfa16c7475154d0e3539a7bcf785',
      },
    }).then((response: any) => {
      response.data.map((item: any) => {
        if (item.name == sucursalName) {
          setStreet(item.street);
        }
      });
    }).catch((err) => console.error(err));
  };

  findFacilitieAdress(hourData.sucursal);

  return (
    <div className="hourInfoConfirmation">
      <div className="title">Información sobre tu hora</div>
      <div className="subTitle">
        Estado de la reserva:
        {' '}
        {status}
      </div>
      <div className="dateInfo">
        {hourData.fecha}
        {' '}
        {hourData.hora}
      </div>
      <div className="dataContainerConfirmation">
        <div className="reservationData">
          <div className="title"><b>Datos de Atención</b></div>
          <div className='infoContainer'>
            <div className=''>
              Profesional
            </div>
            <div style={{ marginTop: 0, marginBottom: 0, fontWeight: 'bold' }}>
              {hourData.profesional}
            </div>
          </div>
          <div className='infoContainer'>
            <div className=''>
              Prestación
            </div>
            <div style={{ marginTop: 0, marginBottom: 0, fontWeight: 'bold' }}>
              {hourData.prestacion}
            </div>
          </div>
          <div className='infoContainer'>
            <div className=''>
              Sucursal
            </div>
            <div style={{ marginTop: 0, marginBottom: 0, fontWeight: 'bold' }}>
              {hourData.sucursal}
            </div>
          </div>
          <div className='infoContainer'>
            <div className=''>
              Dirección
            </div>
            <div style={{ marginTop: 0, marginBottom: 0, fontWeight: 'bold' }}>
              {street}
            </div>
          </div>
        </div>
      </div>
      <div className="buttonsConfirmation">
        <button type="submit" onClick={toHome} style={{ width: '84%' }}>Volver al inicio</button>
        {status === 'no confirmado' && (
          <div className='extraButtons'>
            <button type="submit" onClick={() => { changeStatusHora(2); }}>Anular Hora</button>
            <button type="submit" onClick={() => { changeStatusHora(1); }}>Confirmar Hora</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmationInformation;
