import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import arraySupport from 'dayjs/plugin/arraySupport';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import MyDatePicker from './MyDatePicker';
import comprobanteReservaHTML from '../constants/combrobanteReservaHTML';
import codigoVerificacionHTML from '../constants/codigoVerificacionHTML';
import {
  ClientDataContainerProps, DataHour, DataLabels, ClientSubmit, Client,
} from './Interfaces';
import paises from '../constants/Countries';
import Loader from '../components/Loader';
import Select from 'react-select';
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";
import Popup from 'reactjs-popup';
import { IoIosAlert } from "react-icons/io";
import { ClipLoader } from 'react-spinners';
import { set } from 'lodash';
import { sleep } from '../utils/utils';

const phoneFormatter = (phone: string) => {
  if (phone?.includes('+569')) {
    return phone.slice(4);
  } if (phone?.length === 9) {
    return phone.slice(1);
  } if (phone?.length > 9 && phone?.includes('569')) {
    return phone.slice(3);
  }
  if (!phone) {
    return '';
  }
  return phone;
};

const dateFormatter = (date: string) => {
  dayjs.extend(arraySupport);
  if (date === '') {
    return dayjs().toDate();
  }
  if (date === undefined) {
    return dayjs().toDate();
  }

  if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
    const [year, month, day] = date?.split('-');
    return dayjs([+year, +month - 1, +day]).toDate();
  }

  const [day, month, year] = date?.split('-');
  return dayjs([+year, +month - 1, +day]).toDate();
};

const dateFormatterNotification = (date: any) => {
  const fecha = dayjs(date).locale('es');

  const hora = fecha.format('HH');
  const minuto = fecha.format('mm');
  const dia = fecha.format('dddd');
  const month = fecha.format('MMMM');
  const dayNumber = fecha.date();
  const capitalizedDay = dia.charAt(0).toUpperCase() + dia.slice(1);
  const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);

  const reservationDate = `${capitalizedDay} ${dayNumber} de ${capitalizedMonth}`;
  const reservationHour = `${hora}:${minuto}`;

  return { reservationDate, reservationHour };
};

const ClientDataContainer = ({
  clientData,
  clientExists,
  dataHour,
  dataLabels,
}: ClientDataContainerProps) => {
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [previsiones, setPrevisiones] = useState([]);
  const [comunas, setComunas] = useState([]);
  const navigate = useNavigate();
  const { reservationDate, reservationHour } = dateFormatterNotification(dataHour.reservationHour);
  const [verificarMail, setVerificaMail] = useState<boolean>(false);
  const [verificatiorNumber, setVerificatorNumber] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [repeatedCodeHour, setRepeatedCodeHour] = useState<any>(null);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const ReservationSchema = Yup.object().shape({
    nombre: Yup.string()
      .required('Campo requerido'),
    paterno: Yup.string()
      .required('Campo requerido'),
    materno: Yup.string()
      .required('Campo requerido'),
    correo: Yup.string()
      .email('Correo inválido')
      .required('Campo requerido')
      .test('verificar', 'Es necesario verificar este correo',
        (field) => {
          if (
            (!clientExists || field.valueOf() !== clientData.correo)
            && !verificarMail
          ) return false;
          return true;
        }),
    direccion: Yup.string()
      .required('Direccion requerida'),
    celular: Yup.number()
      .required('Campo requerido')
      .min(10000000, 'El número debe ser de 8 dígitos')
      .max(99999999, 'El número debe ser de 8 dígitos')
      .typeError('Por favor ingresa números solamente'),
    verificador: Yup.number().when('verificar',
      (verificar, field) => (verificarMail
        ? field.required('Campo requerido')
          .typeError('Por favor ingresa números solamente')
          .test('isCorrect',
            'El número ingresado es incorrecto',
            (fieldF) => {
              if (fieldF.valueOf() === verificatiorNumber) return true;
              return false;
            }) : field)),
  });

  const toHome = () => {
    window.location.replace('https://maiposalud.cl');
  };
  const toReserve = () => {
    navigate('/filter', { state: { message: `${dataHour.idGroupConsultHour}`, clientData } });
  };
  const cancelarHora = () => {
    setIsCancelling(true)
    axios.post('https://alma.maiposalud.com/alma/external/v1/confirmationWeb',
      {
        id_agendamiento: repeatedCodeHour,
        status: 2,
      },
      {
        headers: {
          Authorization: '2374dfa16c7475154d0e3539a7bcf785',
          'Content-Type': 'multipart/form-data',
        },
      }).catch().then(() => {
        setIsCancelling(false)
        navigate('/cancel-reservation', { state: { id: `${dataHour.idGroupConsultHour}`, clientData } });
      }
      );
  }
  const VerificationEmail = async (
    clientDataF: ClientSubmit,
    hourData: DataHour,
    dataLabelsF: DataLabels,
  ) => {
    const data = {
      sender: { name: 'Maiposalud', email: 'reserva@maiposalud.cl' },
      to: [{ email: clientDataF.correo, name: 'Comprobante de reserva' }],
      subject: 'Reserva web exitosa',
      htmlContent: comprobanteReservaHTML(
        clientDataF,
        hourData,
        dataLabelsF,
        reservationDate,
        reservationHour
      ),
    };
    axios.post('https://api.sendinblue.com/v3/smtp/email', data, {
      headers: {
        'api-key': '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',
        'Content-Type': 'application/json',
      },
    }).then().catch((err) => console.error(err));
  };

  const sendVerificationNumber = async (codigo: number | string, correo: string) => {
    const data = {
      sender: { name: 'Maiposalud', email: 'reserva@maiposalud.cl' },
      to: [{ email: correo, name: 'Correo a verificar' }],
      textContent: `Tu código de verificación es: ${codigo}`,
      subject: 'Código de verificación',
      htmlContent: codigoVerificacionHTML(
        clientData,
        codigo
      ),
    };
    axios.post('https://api.sendinblue.com/v3/smtp/email', data, {
      headers: {
        'api-key': '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',
        'Content-Type': 'application/json',
      },
    }).then().catch((err) => console.error(err));
  };

  const verificationNumber = (correo: string) => {
    const code = Math.floor(Math.random() * (999999 - 99999) + 99999);
    setVerificaMail(true);
    setVerificatorNumber(code);
    sendVerificationNumber(code, correo);
  };

  const reserveHour = (
    dataHourF: DataHour,
    clientDataF: ClientSubmit,
    dataLabelsF: DataLabels,
  ) => {
    const [fecha, hora] = dataHourF.reservationHour.split(' ');
    const body = {
      rut: clientDataF.rut,
      id: dataHourF.idProfesional,
      fecha,
      hora,
      facility: dataHourF.facility,
      categorie: dataHourF.categorie,
    };
    axios.post('https://alma.maiposalud.com/alma/external/v1/eventsWeb',
      body,
      {
        headers: {
          Authorization: '2374dfa16c7475154d0e3539a7bcf785',
          'Content-Type': 'multipart/form-data',
        },
      }).then((response: any) => {
        dataHourF.reservationCode = response.data.id;
        VerificationEmail(clientDataF, dataHourF, dataLabelsF);
        const prevision: any = previsiones.find(
          (item: any) => item.option_id === clientDataF.prevision,
        );
        const previsionName = prevision ? prevision.title : 'Sin previsión';
        navigate('/reservation-data', {
          state: {
            dataHour: dataHourF,
            clientData: clientDataF,
            dataLabels: dataLabelsF,
            reservationDate,
            reservationHour,
            previsionName,
          },
        });
      }).catch((err: any) => {
        console.error(err.response.data.message);
        console.error(err.response.data);
        if (err.response.data.message === 'No es posible concretar tu reserva, porque ya tienes una cita para la misma prestación.') {
          setOpenModal(true);
          setRepeatedCodeHour(err.response.data.id)
        }
        console.error(err);
      });
  };

  const createUser = (
    dataHourF: DataHour,
    clientDataF: ClientSubmit,
    dataLabelsF: DataLabels,
  ) => {
    const body = {
      rut: clientDataF.rut,
      nombre: clientDataF.nombre,
      paterno: clientDataF.paterno,
      materno: clientDataF.materno,
      direccion: clientDataF.direccion,
      comuna: clientDataF.comuna,
      correo: clientDataF.correo,
      sexo: clientDataF.sexo,
      fecha_nacimiento: clientDataF.fechaNacimiento,
      prevision: clientDataF.prevision,
      celular: clientDataF.celular,
      nacionalidad: clientDataF.nacionalidad,
      ocupacion: clientDataF.ocupacion,
      token_app: 'none',
    };
    axios.post('https://alma.maiposalud.com/alma/external/pp/v1/insertPatient',
      body,
      {
        headers: {
          Authorization: '2374dfa16c7475154d0e3539a7bcf785',
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        reserveHour(dataHourF, clientDataF, dataLabelsF);
      }).catch((err) => { console.error(err); });
  };

  const isUserChange = (clientOriginal: Client, clientUpdate: ClientSubmit) => {
    if (clientOriginal.nombre !== clientUpdate.nombre) {
      return true;
    } if (clientOriginal.paterno !== clientUpdate.paterno) {
      return true;
    } if (clientOriginal.materno !== clientUpdate.materno) {
      return true;
    } if (clientOriginal.direccion !== clientUpdate.direccion) {
      return true;
    } if (clientOriginal.comuna !== clientUpdate.comuna) {
      return true;
    } if (clientOriginal.correo !== clientUpdate.correo) {
      return true;
    } if (clientOriginal.sexo !== clientUpdate.sexo) {
      return true;
    } if (clientOriginal.fechaNacimiento !== clientUpdate.fechaNacimiento) {
      return true;
    } if (clientOriginal.prevision !== clientUpdate.prevision) {
      return true;
    } if (clientOriginal.celular !== clientUpdate.celular) {
      return true;
    } if (clientOriginal.nacionalidad !== clientUpdate.nacionalidad) {
      return true;
    }

    return false;
  };

  const updateUser = (
    dataHourF: DataHour,
    clientDataF: ClientSubmit,
    dataLabelsF: DataLabels,
  ) => {
    const body = {
      rut: clientDataF.rut,
      nombre: clientDataF.nombre,
      paterno: clientDataF.paterno,
      materno: clientDataF.materno,
      direccion: clientDataF.direccion,
      comuna: clientDataF.comuna,
      correo: clientDataF.correo,
      sexo: clientDataF.sexo,
      fecha_nacimiento: clientDataF.fechaNacimiento,
      prevision: clientDataF.prevision,
      celular: clientDataF.celular,
      nacionalidad: clientDataF.nacionalidad,
      ocupacion: clientDataF.ocupacion,
      token_app: 'null',
    };
    axios.post('https://alma.maiposalud.com/alma/external/pp/v1/updatePatient',
      body,
      {
        headers: {
          Authorization: '2374dfa16c7475154d0e3539a7bcf785',
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        reserveHour(dataHourF, clientDataF, dataLabelsF);
      }).catch((err) => { console.error(err); });
  };
  useEffect(() => {
    if (!dataLoaded) {
      axios.post('https://alma.maiposalud.com/alma/external/pp/v1/prevision', '', {
        headers: {
          Authorization: '2374dfa16c7475154d0e3539a7bcf785',
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        setPrevisiones(response.data);
        setDataLoaded(true);
      }).catch((err) => {
        console.error(err);
      });

      axios.post('https://alma.maiposalud.com/alma/external/pp/v1/commune', '', {
        headers: {
          Authorization: '2374dfa16c7475154d0e3539a7bcf785',
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        setComunas(response.data);
        setDataLoaded(true);
      }).catch((err) => {
        console.error(err);
      });
    }
  }, [dataLoaded]);

  const colourStyles = {
    control: (base: any, state: any) => ({
      ...base,
      backgroundColor: 'white',
      border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? 0 : 0,
      },
      fontWeight: 'lighter',
      fontSize: '14px',
    }),
    menu: (base: any, state: any) => ({
      ...base,
      backgroundColor: 'white',
      border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? 0 : 0,
      },
      fontWeight: 'lighter',
      fontSize: '14px',
    }),
    placeholder: (base: any) => ({
      ...base,
      color: '#80808060',
      fontWeight: 'lighter',
      fontSize: '14px',
    }),

  };

  const PopUp = () => {
    return (
      <Popup open={openModal} modal closeOnDocumentClick={false}>
        <div className='popUpContainer'>
          <div style={{ display: 'flex', flexDirection: 'row', width: '25%', paddingTop: '3%' }}>
            <IoIosAlert size={40} color='#52A92B' />
            <p className='popUpTitle'>Alerta</p>
          </div>
          <p className='popUpSubtitle'>Lo sentimos. No es posible concretar tu reserva, porque ya tienes una cita para la misma especialidad. El código de su reserva es {repeatedCodeHour}.</p>
          <div className="buttons">
            <div style={{ width: '100%' }}>
              <button disabled={isCancelling} className='containerTopButton' type="submit" onClick={toReserve}>Reservar otra hora</button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', }}>
              <button disabled={isCancelling} className='containerBottomButton' type="submit" onClick={toHome}>Volver al inicio</button>
              <button disabled={isCancelling} className='containerBottomButton' type="submit" onClick={cancelarHora}>
                {isCancelling ? <ClipLoader size={14} color="#78cd51" /> : 'Anular hora'}
              </button>
            </div>
          </div>
        </div>
      </Popup>
    )
  }

  return (
    <div className="clientDataContainerH">
      {/* <h2>
        {clientExists
          ? 'Por favor confírmanos algunos datos'
          : 'Por favor ingresa tus datos para continuar con el agendamiento'}
      </h2> */}
      <p className="containerTitleReservation">
        <HiOutlineClipboardDocumentCheck style={{ marginRight: '4%' }} size={34} />
        Reserva de hora
      </p>
      <p className="containerSubTitle">
        {'Por favor ingrese sus datos para continuar con el agendamiento'}
      </p>
      <div className="clientInputDataContainer">
        <Formik
          initialValues={{
            nombre: (clientData.nombre ? clientData.nombre : ''),
            paterno: (clientData.paterno ? clientData.paterno : ''),
            materno: (clientData.materno ? clientData.materno : ''),
            nacimiento: dateFormatter(clientData.fechaNacimiento),
            sexo: (clientData.sexo ? clientData.sexo : 'M'),
            prevision: (clientData.prevision ? clientData.prevision : '0'),
            direccion: (clientData.direccion ? clientData.direccion : ''),
            comuna: (clientData.comuna ? clientData.comuna : ''),
            correo: (clientData.correo ? clientData.correo : ''),
            nacionalidad: (clientData.nacionalidad ? clientData.nacionalidad : 'Chile'),
            celular: `${phoneFormatter(clientData.celular)}`,
            edad: (clientData.edad ? clientData.edad : ''),
            fechaNacimiento: '',
            verificador: '',
            ocupacion: (clientData.occupation ? clientData.occupation : ''),
          }}
          onSubmit={async (values) => {
            setIsLoading(true);
            const client: ClientSubmit = { ...values };
            client.fechaNacimiento = dayjs(values.nacimiento).format('YYYY-MM-DD');
            client.rut = clientData.rut;
            client.celular = `+569${values.celular}`;
            const userNeedUpdate = isUserChange(clientData, client);
            await sleep(1000);

            if (!clientExists) {
              // console.log("Debo crear al usuario")
              await createUser(dataHour, client, dataLabels);
            } else if (verificarMail || userNeedUpdate) {
              // console.log("Debo actualizar al usuario")
              await updateUser(dataHour, client, dataLabels);
            } else {
              // console.log("Debo reservar la hora")
              await reserveHour(dataHour, client, dataLabels);
            }

            setIsLoading(false);
          }}
          validationSchema={ReservationSchema}
        >
          {({ errors, touched, values }) => (
            <Form>
              {!clientData.nombre
                ? (
                  <>
                    <label className="labelForm"><b>Nombre</b></label>
                    <Field id="nombre" name="nombre" className="fieldForm" placeholder='Ingrese su nombre' />

                  </>
                )
                : null}
              {!clientData.paterno
                ? (
                  <>
                    <label className="labelForm"><b>Apellido Paterno</b></label>
                    <Field id="paterno" name="paterno" className="fieldForm" placeholder='Ingrese apellido paterno' />
                  </>
                )
                : null}
              {!clientData.materno
                ? (
                  <>
                    <label className="labelForm"><b>Apellido Materno</b></label>
                    <Field id="materno" name="materno" className="fieldForm" placeholder='Ingrese apellido materno' />
                  </>
                )
                : null}
              {!clientData.sexo
                ? (
                  <>
                    <label className="labelForm"><b>Sexo</b></label>
                    <Select
                      placeholder="Selecciona un sexo"
                      className="formInput"
                      styles={colourStyles}
                      options={[
                        { value: 'M', label: 'Masculino' },
                        { value: 'F', label: 'Femenino' },
                      ]}
                      onChange={(sexo: any) => {
                        values.sexo = sexo.value;
                      }}
                    />
                  </>
                )
                : null}
              {!clientData.occupation
                ? (
                  <>
                    <label className="labelForm"><b>Ocupación</b></label>
                    <Select
                      placeholder="Selecciona una ocupación"
                      className="formInput"
                      styles={colourStyles}
                      options={[
                        { value: 'Trabajador dependiente', label: 'Trabajador dependiente' },
                        { value: 'Trabajador independiente', label: 'Trabajador independiente' },
                        { value: 'Estudiante', label: 'Estudiante' },
                        { value: 'Sin ocupación', label: 'Sin ocupación' }
                      ]}
                      onChange={(ocupacion: any) => {
                        values.ocupacion = ocupacion.value;
                      }}
                    />
                  </>
                )
                : null}
              {!clientData.prevision
                ? (
                  <>
                    <label className="labelForm"><b>Previsión</b></label>
                    <Select
                      placeholder="Selecciona una previsión"
                      className="formInput"
                      styles={colourStyles}
                      options={previsiones.map((item: any) => ({ value: item.option_id, label: item.title }))}
                      onChange={(prevision: any) => {
                        values.prevision = prevision.value;
                      }}
                    />
                  </>
                )
                : null}
              {!clientData.nacionalidad
                ? (
                  <>
                    <label className="labelForm"><b>Nacionalidad</b></label>
                    <Select
                      placeholder="Selecciona una nacionalidad"
                      className="formInput"
                      styles={colourStyles}
                      options={paises.map((item: any) => ({ value: item, label: item }))}
                      onChange={(nacionalidad: any) => {
                        values.nacionalidad = nacionalidad.value;
                      }}
                    />
                  </>
                )
                : null}
              {!clientData.direccion
                ? (
                  <>
                    <label className="labelForm"><b>Dirección</b></label>
                    <Field id="direccion" name="direccion" className="fieldForm" placeholder='Ej: General Freire 067' />
                    {errors.direccion && touched.direccion
                      ? (<div className="error">{String(errors.direccion)}</div>)
                      : null}
                  </>
                )
                : null}
              {!clientData.comuna
                ? (
                  <>
                    <label className="labelForm"><b>Comuna</b></label>
                    <Select
                      placeholder="Selecciona una comuna"
                      className="formInput"
                      styles={colourStyles}
                      options={comunas.map((item: any) => ({ value: item.nombre, label: item.nombre }))}
                      onChange={(comuna: any) => {
                        values.comuna = comuna.value;
                      }}
                    />
                  </>
                )
                : null}
              <>
                <label className="labelForm"><b>Fecha de nacimiento</b></label>
                <MyDatePicker label="nacimiento" />
              </>
              <>
                <label className="labelForm"><b>Correo</b></label>
                <div className="dataField">
                  <Field id="correo" name="correo" className="formInput" placeholder='correo@ejemplo.com' />
                  {/* <Field id="correo" name="correo" className="fieldInput" type="email" /> */}
                  {!clientExists || values.correo !== clientData.correo
                    ? <button type="button" onClick={() => { verificationNumber(values.correo); }}>Verificar correo</button>
                    : null}
                </div>
                {errors.correo && touched.correo
                  ? (<div className="error">{String(errors.correo)}</div>)
                  : null}
                {verificarMail ? (
                  <div>
                    <label htmlFor="validacion"><b>Ingresa el código enviado a tu correo</b></label>
                    <Field id="verificador" name="verificador" type="number" className="fieldInput" />
                    {errors.verificador && touched.verificador
                      ? (<div className="error">{String(errors.verificador)}</div>)
                      : null}
                  </div>
                ) : null}
              </>
              <>
                <label className="labelForm"><b>Celular</b></label>
                <div className="dataField">
                  <p style={{ marginBottom: '3%' }}>+569</p>
                  <Field id="celular" name="celular" className="formInput" placeholder='87654321' />
                  {/* <Field id="celular" name="celular" className="fieldInput" /> */}
                </div>
                {errors.celular && touched.celular
                  ? (<div className="error">{String(errors.celular)}</div>)
                  : null}
              </>
              {isLoading
                ? (<Loader color="var(--principal-color)" />)
                : <button className="reservationButton" type="submit" disabled={isLoading}>Reservar Hora</button>}
            </Form>
          )}
        </Formik>
      </div>
      <PopUp />
    </div>
  );
};

export default ClientDataContainer;
